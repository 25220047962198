import { Component, OnInit } from '@angular/core';
import { PointProductInterface } from 'src/app/models/product.model';
import { PointProductService } from 'src/app/services/point-product.service';

@Component({
  selector: 'app-regiones',
  templateUrl: './regiones.component.html',
  styleUrls: ['./regiones.component.scss']
})
export class RegionesComponent implements OnInit {
  lat: number;
  lng: number;
  zoom: number;
  mapTypeId: string;

  regiones: PointProductInterface[];

  constructor(private fbPointProduct: PointProductService) { }

  ngOnInit() {
    this.lat = 17.221389,
      this.lng = -95.896588
    this.zoom = 8
    
    this.mapTypeId = 'hybrid'
   this.getPointProduct()

    if (window.screen.width <= 428) {
      this.zoom = 9
    }
  }

  getPointProduct() {
    this.fbPointProduct.getRegionesProduct().subscribe(pointProduct => this.regiones = pointProduct)
  }
}
