import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ListProyectInterface } from "../models/product.model";

@Injectable()
export class ListProyectoService {
     listProyectosCollection: AngularFirestoreCollection<ListProyectInterface>
    proyectosDictaminadosCollection: AngularFirestoreCollection<ListProyectInterface>

    listProyectos: Observable<ListProyectInterface[]>
    listProyectosDictaminados: Observable<ListProyectInterface[]>
 

    proyectos = {
        "numerosId": 143,
        "nameProyectos": "EQUIPAMIENTO PARA PALENQUE ",
        "region": "V. CENTRALES",
        "municipio": "COLONIA EMILIANO ZAPATA, SANTA CRUZ MIXTEPEC",
        "localidad": "COLONIA EMILIANO ZAPATA, SANTA CRUZ MIXTEPEC",
        "razonSocial": "PERSONA FISICA",
        "estatal": 60000,
        "financiamiento": 240000,
        "montoProyecto": 300000,
        "beneficiarios": 4,
        "empleosDirectos": 5,
        "empleosIndirectos": 18
    }

    constructor(public db: AngularFirestore) {
        //this.listProyectosCollection = this.db.collection('proyectos-inversion', ref => ref)
        this.proyectosDictaminadosCollection = this.db.collection('proyectos-dictaminados', ref => ref)
    }

/*     addProyectos() {
        this.listProyectosCollection.add(this.proyectos)
    }
    getListProyectos(): Observable<ListProyectInterface[]> {
        this.listProyectos = this.listProyectosCollection.snapshotChanges().pipe(map(
            changes => {
                return changes.map(actions => {
                    const data = actions.payload.doc.data() as ListProyectInterface;
                    return data;

                })
            }))
        return this.listProyectos
    }

*/
    getListProyectosDictaminados(): Observable<ListProyectInterface[]> {
        this.listProyectosDictaminados = this.proyectosDictaminadosCollection.snapshotChanges().pipe(map(
            changes => {
                return changes.map(actions => {
                    const data = actions.payload.doc.data() as ListProyectInterface;
                    return data;

                })
            }))
        return this.listProyectosDictaminados
    }
/*
    searchUsers(searchValue) {
        this.listProyectosDictaminados = this.proyectosDictaminadosCollection.snapshotChanges().pipe(map(
            changes => {
                return changes.map(actions => {
                    const data = actions.payload.doc.data() as ListProyectInterface;
                    return data;

                })
            }))
        return this.listProyectosDictaminados
    } 
 */

    /* getAvatars() {
        return this.db.collection('/avatar').valueChanges()
    }

    getUser(userKey) {
        return this.db.collection('users').doc(userKey).snapshotChanges();
    }

    updateUser(userKey, value) {
        value.nameToSearch = value.name.toUpperCase();
        return this.db.collection('users').doc(userKey).set(value);
    }

    deleteUser(userKey) {
        return this.db.collection('users').doc(userKey).delete();
    } */

    getListProyectos() {
        return this.db.collection('proyectos-inversion').snapshotChanges()
    }

    searchListProyectos(searchValue) {
        return this.db.collection('proyectos-inversion', ref => ref.where('localidad', '>=', searchValue))
            .snapshotChanges()
    }

    searchByEmpleosListProyectos(value) {
        return this.db.collection('proyectos-inversion', ref => ref.orderBy('empleosDirectos').startAt(value)).snapshotChanges();
    }
    searchByBeneficiariosListProyectos(value) {
        return this.db.collection('proyectos-inversion', ref => ref.orderBy('beneficiarios').startAt(value)).snapshotChanges();
    }


    createListProyectos() {
        return this.db.collection('proyectos-inversion').add(this.proyectos);
    }
}