import { Component, OnInit } from '@angular/core';
import { PointProductInterface } from 'src/app/models/product.model';
import { PointProductService } from 'src/app/services/point-product.service';

@Component({
  selector: 'app-point-product',
  templateUrl: './point-product.component.html',
  styleUrls: ['./point-product.component.scss']
})
export class PointProductComponent implements OnInit {
  lat: number;
  lng: number;
  zoom: number;
  mapTypeId: string;
  
  pointProduct: PointProductInterface[];
  constructor(private fbPointProduct: PointProductService) { }

  ngOnInit() {
    this.lat = 17.221389, 
      this.lng = -95.896588 
    this.zoom = 8
    this.mapTypeId = 'terrain'
    this.getPointProduct()

    if(window.screen.width <= 428) {
      this.zoom = 9
    }
  }

  getPointProduct() {
    this.fbPointProduct.getPointProduct().subscribe(pointProduct=> this.pointProduct= pointProduct)
  }

}
