import { Component, OnInit } from '@angular/core';
import { faList, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductTitleInterface } from 'src/app/models/product.model';
import { ProductTitleService } from 'src/app/services/product-title.service';

@Component({
  selector: 'app-cadena-exportacion',
  templateUrl: './cadena-exportacion.component.html',
  styleUrls: ['./cadena-exportacion.component.scss']
})
export class CadenaExportacionComponent implements OnInit {
  id: string;
  name: string;
  icon: string;

  searchValue: string = "";
  localidadFiltros: Array<any>;
  items: Array<any>;

  closeResult = '';
  listProductosExportacion: ProductTitleInterface[];
  regionsTitle: ProductTitleInterface[];

  constructor(private fbPointProduct: ProductTitleService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getCadenasValor()
  }

  getRegionDetails() {
    this.fbPointProduct.getRegionListTitle().subscribe(regionsTitle => this.regionsTitle = regionsTitle)
  }
  /*  getPointProduct() {
     this.fbPointProduct.getListCadenasValor().subscribe(pointProduct => this.listProductosExportacion = pointProduct)
   } */

  getCadenasValor() {
    this.fbPointProduct.getListCadenasValor()
      .subscribe(result => {
        this.items = result;
        this.localidadFiltros = result;
      })
  }

  verDetalles(name: string, icon, content) {
    this.name = name;
    this.icon = icon;
    console.log(name)
    this.fbPointProduct.setIdNameCollection(name)
    this.getRegionDetails()
    this.open(content)
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  searchByLocalidad() {
    let value = this.searchValue.toLowerCase();
    this.fbPointProduct.searchListCadenasValor(value)
      .subscribe(result => {
        this.localidadFiltros = result;
        this.items = result;
      })
  }
}
