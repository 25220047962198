import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { ProductTitleInterface } from "../models/product.model";
import { map } from 'rxjs/operators';

@Injectable()
export class ProductTitleService {
  productTitleCollection: AngularFirestoreCollection<ProductTitleInterface>
  regionProductCollection: AngularFirestoreCollection<ProductTitleInterface>
  productTitles: Observable<ProductTitleInterface[]>
  regionTitles: Observable<ProductTitleInterface[]>

  constructor(public db: AngularFirestore) {
    //this.productTitleCollection = this.db.collection('list-title-product', ref => ref)
  }

  getListCadenasValor() {
    return this.db.collection('list-title-product').snapshotChanges()
  }

  searchListCadenasValor(searchValue) {
    return this.db.collection('list-title-product', ref => ref.where('id', '>=', searchValue))
      .snapshotChanges()
  }


 /*  getProductListTitle(): Observable<ProductTitleInterface[]> {
    this.productTitles = this.productTitleCollection.snapshotChanges().pipe(map(
      changes => {
        return changes.map(actions => {
          const data = actions.payload.doc.data() as ProductTitleInterface;
          return data;

        })
      }))
    return this.productTitles
  } */

  // get sub collection region 
  setIdNameCollection(name: string) { 
    const url = `/list-title-product/${name}/region` 
    this.regionProductCollection = this.db.collection(url, ref => ref)
  }


  getRegionListTitle(): Observable<ProductTitleInterface[]> {
    this.regionTitles = this.regionProductCollection.snapshotChanges().pipe(map(
      changes => {
        return changes.map(actions => {
          const data = actions.payload.doc.data() as ProductTitleInterface;
          return data;
        })
      }))
    return this.regionTitles
  }
}