import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { ListProyectInterface } from 'src/app/models/product.model';
import { ListProyectoService } from 'src/app/services/list-proyecto.service';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {
  proyectosDictaminados: ListProyectInterface[];

  empleosValue: number = 0;
  beneficiarioValue: number = 0;
  searchValue: string = "";
  items: Array<any>;
  filterEmpleosDirectos: Array<any>;
  filterBeneficiarios: Array<any>;
  localidadFiltros: Array<any>;

  constructor(
    public firebaseService: ListProyectoService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getListDictaminados()
    this.getProyectoList();
  }

  getListDictaminados() {
    this.firebaseService.getListProyectosDictaminados().subscribe(dataProyectos => this.proyectosDictaminados = dataProyectos)
  }

  getProyectoList() {
    this.firebaseService.getListProyectos()
      .subscribe(result => {
        this.items = result;
        this.localidadFiltros = result;
        this.filterEmpleosDirectos = result;
        this.filterBeneficiarios = result;
      })
  }

/*   viewDetails(item) {
    this.router.navigate(['/details/' + item.payload.doc.id]);
  } */

 /*  capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
 */
  searchByLocalidad() {
    let value = this.searchValue.toUpperCase();
    this.firebaseService.searchListProyectos(value)
      .subscribe(result => {
        this.localidadFiltros = result;
        this.items = this.combineLists(result, this.filterEmpleosDirectos, this.filterBeneficiarios);
      })
  }

  searchEmpleosDirectos(event) {
    this.firebaseService.searchByEmpleosListProyectos(event.value)
      .subscribe(result => {
        this.filterEmpleosDirectos = result;
        this.items = this.combineLists(result, this.localidadFiltros, this.filterBeneficiarios);
      })
  }

  searchBeneficiarios(event) {
    this.firebaseService.searchByBeneficiariosListProyectos(event.value)
      .subscribe(result => {
        this.filterBeneficiarios = result;
        this.items = this.combineLists(result, this.localidadFiltros, this.filterEmpleosDirectos);
      })
  }

  combineLists(a, b,c) {
    let result = [];

    a.filter(x => {
      return b.filter(x2 => {
        if (x2.payload.doc.id == x.payload.doc.id) {
          result.push(x2);
        }
      });
    });
    return result;
  }

  SubirProyectos() {
    this.firebaseService.createListProyectos()
  }
}
