import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddPointProductComponent } from './components/add-point-product/add-point-product.component';
import { HomeComponent } from './components/home/home.component';
import { ProyectosComponent } from './components/somos/proyectos/proyectos.component';
import { EvidenciasComponent } from './components/somos/evidencias/evidencias.component';
import { MvoComponent } from './components/somos/mvo/mvo.component';
import { DirectorioComponent } from './components/somos/directorio/directorio.component';
import { OrganigramaComponent } from './components/somos/organigrama/organigrama.component';
import { ComentariosComponent } from './components/somos/comentarios/comentarios.component';
import { LisTecnicosComponent } from './components/somos/lis-tecnicos/lis-tecnicos.component';
import { CadenasValorComponent } from './components/cadenas-valor/cadenas-valor.component';
import { CadenaExportacionComponent } from './components/cadena-exportacion/cadena-exportacion.component';
import { CertificacionesComponent } from './components/certificaciones/certificaciones.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { RegionesComponent } from './components/regiones/regiones.component';

const routes: Routes = [
  /* {path:'agregar', component: AddPointProductComponent}, */
/*   { path: 'proyectos', component: ProyectosComponent },
 */  { path: 'evidencias', component: EvidenciasComponent },
  { path: 'mision-visio-objetivo', component: MvoComponent },
  { path: 'directorio', component: DirectorioComponent },
/*   { path: 'organigrama', component: OrganigramaComponent },
 */  { path: 'comentarios', component: ComentariosComponent },
  { path: 'tecnicos', component: LisTecnicosComponent },
  { path: 'ubicacion-valor', component: CadenasValorComponent },
  { path: 'regiones', component: RegionesComponent },

  { path: 'cadenas-valor', component: CadenaExportacionComponent },
  /*   { path: 'certificaciones', component: CertificacionesComponent },
   */
  { path: '', component: HomeComponent },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
