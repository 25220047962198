import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadenas-valor',
  templateUrl: './cadenas-valor.component.html',
  styleUrls: ['./cadenas-valor.component.scss']
})
export class CadenasValorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
