import { Component, OnInit } from '@angular/core';
import { CertificacionesInterface } from 'src/app/models/product.model';
import { CertificacionesService } from 'src/app/services/certificaciones.service';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html',
  styleUrls: ['./certificaciones.component.scss']
})
export class CertificacionesComponent implements OnInit {
  listCertificaciones: CertificacionesInterface[];
  constructor(private fbCertServices: CertificacionesService) { }

  ngOnInit() {
    this.getListProyectos()
  }

  getListProyectos() {
    this.fbCertServices.getcertOgranico().subscribe(dataProyectos => this.listCertificaciones = dataProyectos)
  }

  organicos() {
    this.fbCertServices.addcertOgranico()
  }

}
