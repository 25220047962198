import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CertificacionesInterface } from '../models/product.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CertificacionesService {

  certOrganicoCollection: AngularFirestoreCollection<CertificacionesInterface>
  certOrganico: Observable<CertificacionesInterface[]>
  certOgranico: Observable<CertificacionesInterface>

  certOrganicos =
    {
      "id": 39,
      "nameEmpresa": "Integradora De Agroindustria y Comercuializacion Agricola SA de CV",
      "nameUnidadProducto": "Centro de Acopio San Pablo Huixtepec",
      "representanteLegal": "Rosa Maria Galvan Carrizales",
      "cultivo": "Jitomate",
      "rendimiento": "20 ton \/semana",
      "volumenComercializacion": 100,
      "localidad": "San pablo Huixtepec",
      "municipio": "San pablo Huixtepec",
      "region": "Valles Centrales",
      "tipoCertificacion": "SRRC",
      "gradoMarginacion": "BAJA",
      "hombres": 6,
      "mujeres": 8
    }

  constructor(public db: AngularFirestore) {
    this.certOrganicoCollection = this.db.collection('certificaciones-organicas', ref => ref)
  }
  addcertOgranico() {
    this.certOrganicoCollection.add(this.certOrganicos)
  }

  getcertOgranico(): Observable<CertificacionesInterface[]> {
    this.certOrganico = this.certOrganicoCollection.snapshotChanges().pipe(map(
      changes => {
        return changes.map(actions => {
          const data = actions.payload.doc.data() as CertificacionesInterface;
          return data;

        })
      }))
    return this.certOrganico
  }
}
