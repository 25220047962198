import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { HttpClientModule } from '@angular/common/http';
import { ProductTitleService } from './services/product-title.service';
import { PointProductComponent } from './components/point-product/point-product.component';
import { PointProductService } from './services/point-product.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddPointProductComponent } from './components/add-point-product/add-point-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { ProyectosComponent } from './components/somos/proyectos/proyectos.component';
import { EvidenciasComponent } from './components/somos/evidencias/evidencias.component';
import { DirectorioComponent } from './components/somos/directorio/directorio.component';
import { OrganigramaComponent } from './components/somos/organigrama/organigrama.component';
import { MvoComponent } from './components/somos/mvo/mvo.component';
import { ComentariosComponent } from './components/somos/comentarios/comentarios.component';
import { LisTecnicosComponent } from './components/somos/lis-tecnicos/lis-tecnicos.component';
import { ListProyectoService } from './services/list-proyecto.service';
import { CadenasValorComponent } from './components/cadenas-valor/cadenas-valor.component';
import { FooterComponent } from './components/footer/footer.component';
import { CadenaExportacionComponent } from './components/cadena-exportacion/cadena-exportacion.component';
import { CertificacionesComponent } from './components/certificaciones/certificaciones.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDividerModule, MatSliderModule, MatToolbarModule } from '@angular/material';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { RegionesComponent } from './components/regiones/regiones.component';

@NgModule({
  declarations: [
    AppComponent,
    PointProductComponent,
    NavbarComponent,
    AddPointProductComponent,
    HomeComponent,
    ProyectosComponent,
    EvidenciasComponent,
    DirectorioComponent,
    OrganigramaComponent,
    MvoComponent,
    ComentariosComponent,
    LisTecnicosComponent,
    CadenasValorComponent,
    FooterComponent,
    CadenaExportacionComponent,
    CertificacionesComponent,
    PagenotfoundComponent,
    RegionesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatSliderModule,
    MatDividerModule,
    MatToolbarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD-Y9Kco-tm3hLnsVEsnco13lvqTtwE_Z0'
    }),
    FontAwesomeModule,
    BrowserAnimationsModule
  ],
  providers: [ProductTitleService,
    PointProductService, ListProyectoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
