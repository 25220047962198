import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PointProductInterface } from "../models/product.model";

@Injectable()
export class PointProductService {
  pointProductCollection: AngularFirestoreCollection<PointProductInterface>
  regionesCollection: AngularFirestoreCollection<PointProductInterface>
  pointProducts: Observable<PointProductInterface[]>
  regiones: Observable<any>

  constructor(public db: AngularFirestore) {
    this.pointProductCollection = this.db.collection('product-location', ref => ref)
    this.regionesCollection = this.db.collection('regiones', ref => ref)
  }
  addPointProduct(pointProduct: PointProductInterface) {
    this.pointProductCollection.add(pointProduct)
  }

  getPointProduct(): Observable<PointProductInterface[]> {
    this.pointProducts = this.pointProductCollection.snapshotChanges().pipe(map(
      changes => {
        return changes.map(actions => {
          const data = actions.payload.doc.data() as PointProductInterface;
          return data;

        })
      }))
    return this.pointProducts
  }

  getRegionesProduct(): Observable<any[]> {
    this.regiones = this.regionesCollection.snapshotChanges().pipe(map(
      changes => {
        return changes.map(actions => {
          const data = actions.payload.doc.data() as PointProductInterface;
          return data;

        })
      }))
    return this.regiones
  }
}