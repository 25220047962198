import { Component, OnInit } from '@angular/core';
import { faEnvelope, faFaceAngry } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: Number;
  mail= faEnvelope;
  face = faFaceAngry
  constructor() {
    let today = new Date();
     this.year = today.getFullYear();
   }
  

  ngOnInit() {
  }

}
