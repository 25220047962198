import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faB } from '@fortawesome/free-solid-svg-icons';
import { PointProductInterface } from 'src/app/models/product.model';
import { PointProductService } from 'src/app/services/point-product.service';

@Component({
  selector: 'app-add-point-product',
  templateUrl: './add-point-product.component.html',
  styleUrls: ['./add-point-product.component.scss']
})
export class AddPointProductComponent implements OnInit {

  form: FormGroup = this.fb.group(
    {
      //description: ['', Validators.required],
      icon: ['', Validators.required],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      localidad: ['', Validators.required],
      municipio: ['', Validators.required],
      region: ['', Validators.required],
      areaSembrado:[''],
      title: ['', Validators.required],
      volumenProduc:[''],
      valorProduc:[''],
      //urlSepia:[''],
      //status:['', Validators.required]
    }
  )


  region: string[] = ['Cañada', 'Costa', 'Istmo', 'Mixteca', 'Papaloapan', 'Sierra Sur', 'Sierra Norte', 'Valles Centrales'];


  constructor(private fb: FormBuilder, private pointService: PointProductService) { }

  ngOnInit() {
  }
  addPointProduct({ value }: { value: PointProductInterface }) {
    this.pointService.addPointProduct(value)
    this.form.reset()
  }
}
